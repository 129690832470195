const global = {
	'body': document.body, // Minimize the number of times we hit the DOM for document.body
	'debounce': (func, wait = 20, immediate = true) => {
		let timeout;
		return function() {
			var context = this,
				args = arguments;
			
			var later = function() {
				timeout = null;
				if(!immediate) {
					func.apply(context, args);
				}
			};
	
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if(callNow) {
				func.apply(context, args)
			};
		}
	},
	'getStickyHeaderHeight': _ => document.querySelector('.sticky-site-header').offsetHeight,
	'screenSize': _ => {
		const getScreenWidth = _ => window.innerWidth;
		if(getScreenWidth() <= 768) return 'mobile';
		if(getScreenWidth() > 768  && getScreenWidth() < 992  ) return 'tablet';
		if(getScreenWidth() >= 992) return 'desktop';
		return null;
	}	
}