;(function() {
	// Thank you to Zell Liew for help and inspiration on this tooltip project!
	const calculatetoolTipPosition = (trigger, toolTip) => {
		const triggerRect = trigger.getBoundingClientRect()
		const toolTipRect = toolTip.getBoundingClientRect()
		const placement = toolTip.dataset.placement
		// spacing between popover and trigger
		const space = 20

		// reposition tooltip based on preferred location
		if (placement === 'top') {
			return {
				left: (triggerRect.left + triggerRect.right) / 2 - toolTipRect.width / 2,
				top: triggerRect.top - toolTipRect.height - space
			}
		}

		if (placement === 'bottom') {
			return {
				left:(triggerRect.left + triggerRect.right) / 2 - toolTipRect.width / 2,
				top: triggerRect.bottom + space
			}
		}

		if (placement === 'right') {
			return {
				left: triggerRect.right + space,
				top: (triggerRect.top + triggerRect.bottom) / 2 - toolTipRect.height / 2
			}
		}

		if (placement === 'left') {
			return {
				left: triggerRect.left - toolTipRect.width - space,
				top: (triggerRect.top + triggerRect.bottom) / 2 - toolTipRect.height / 2
			}
		}
	}

	const gettoolTip = trigger => {
		const target = trigger.dataset.target
		return target ? document.querySelector(`#${target}`) : makeTooltip(trigger)
	}

	const makeTooltip = trigger => {
		const { content, placement } = trigger.dataset
		// Consider using a template literal in the future.
		// for now, ship it!
		const toolTip = document.createElement('div')
		toolTip.classList.add('tooltip')
		toolTip.dataset.placement = placement
		toolTip.innerHTML = `${content}`

		document.body.appendChild(toolTip)
		return toolTip
	}

	const triggers = document.querySelectorAll('.trigger')

	if (triggers) {
		triggers.forEach(trigger => {
			const toolTip = gettoolTip(trigger)
			const toolTipPos = calculatetoolTipPosition(trigger, toolTip)
			// Apply inline styling
			toolTip.style.left = `${toolTipPos.left}px`
			toolTip.style.top = `${toolTipPos.top}px`

			trigger.addEventListener('mouseenter', e => {
				toolTip.classList.toggle('is-active')
			})
			trigger.addEventListener('mouseleave', e => {
				toolTip.classList.toggle('is-active')
			})
		})
	}
})()
