;(function(){
	const tabbedContainers = document.querySelectorAll('.l_ms_tabbed-content');

	if(tabbedContainers) {
		tabbedContainers.forEach(tabbedContainer => {
			const isActive = 'is-active';
			const triggers = tabbedContainer.querySelector('.tabbed-triggers');
			const triggerLinks = tabbedContainer.querySelectorAll('a');
			const containerItems = tabbedContainer.querySelectorAll('.tabbed-container-item');
	
			triggers.addEventListener('click', e => {
				e.preventDefault();
	
				const link = e.target.closest('a');
				if(!link) return;
	
				const href = link.getAttribute('href');
				
				const associatedContainer = tabbedContainer.querySelector(`${href}`);
	
				triggerLinks.forEach(trigger => {
					trigger.classList.remove(isActive);
				});
	
				containerItems.forEach(containerItem => {
					containerItem.classList.add('visually-hidden');
				});
	
				link.classList.add(isActive);
				associatedContainer.classList.remove('visually-hidden');
			})
		});
	}
})();