;(function () {
	// TODO: REFACTOR to use smooth-scroll functionality
	// -------------------------------------------------------------
	// Scroll To Top of Page
	// -------------------------------------------------------------
	
	// Uses an anchor link with an ID of top to scroll to the top of the pgae.
	const topLinks = document.querySelectorAll('a[href="#top"]');
	topLinks.forEach(item => {
		const scrollToTop = e => {
			if (global.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
				// https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollBy
				window.scrollBy(0, -50);
				// https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
				requestAnimationFrame(scrollToTop);
			}
		}

		item.addEventListener('click', e => {
			e.preventDefault();
			scrollToTop();
		});
	})

})()

