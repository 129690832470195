;(function(){
	const menuOpenButton = document.querySelector('#menu-open');
	const menuCloseButton = document.querySelector('.toggle-menu');
	const footerPrimary = document.querySelector('.c_footer-primary');

	const pageToggle = () => global.body.classList.toggle('off-canvas-active');
	const addPageBlur = (e) => {
		if(footerPrimary) {
			footerPrimary.insertAdjacentHTML('afterend', '<div class="opaque-container w-screen h-screen inset-0 z-40 fixed bg-opaque cursor-pointer"></div>');
		}
	}

	const removePageBlur = () => {
		const blurWrapper = document.querySelector('.opaque-container');
		if (blurWrapper) {global.body.removeChild(blurWrapper)}
	}

	const pageOpen = () => {
		pageToggle();
		addPageBlur();
	}

	const pageClose = () => {
		pageToggle();
		removePageBlur();
	}

	if(menuOpenButton) menuOpenButton.addEventListener('click', pageOpen);
	if(menuCloseButton) menuCloseButton.addEventListener('click', pageClose);

	window.addEventListener('click', e => {
		if(e.target.matches('.opaque-container')) {
			pageClose();
		}
	})

	// ----------------------------------------------------
	// Close menu with escape key
	// ----------------------------------------------------
	
	window.addEventListener('keydown', e => {
		if(e.keyCode === 27 && global.body.classList.contains('off-canvas-active')) {
			pageClose();
		}
	});
})()
