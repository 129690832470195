;(function () {
	// -------------------------------------------------------------
	// Sticky Header
	// -------------------------------------------------------------
	const stickyHeader = 'sticky-header';
	
	const fixStickyHeader = _ => {
		if(window.scrollY > 0) {
			global.body.classList.add(stickyHeader);
		} else {
			global.body.classList.remove(stickyHeader);
		}
	}
	
	if(stickyHeader) window.addEventListener('scroll', fixStickyHeader);
})()