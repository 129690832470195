;(function () {
	const carousel = document.querySelector('.carousel');
	if (carousel) {
		carousel.removeAttribute('hidden');
		const flkty = new Flickity( carousel, {
			autoPlay: 5000,
			lazyLoad: true,
			percentPosition: false
		});
	}
})()