;(function () {
	const modal = document.querySelectorAll('.m_modal');
	const modalOpen = document.querySelectorAll('.m_modal-open');
	const modalClose = document.querySelectorAll('.m_modal-close');

	if(modal) {
		const stopDefaultAndPropagation = (e) => {
			e.preventDefault();
			e.stopPropagation();
		}
	
		modalOpen.forEach(item => {
			item.addEventListener('click', e => {
				stopDefaultAndPropagation(e);
				const openModalId = item.getAttribute('data-modal-open');
				const openAssociatedModal = document.getElementById(openModalId);
				// From MDN https://developer.mozilla.org/en-US/docs/Web/API/Element/removeAttribute
				// Note: Since removeAttribute() doesn't return a value, you can't chain multiple calls together to remove multiple attributes at once.
				openAssociatedModal.removeAttribute('aria-hidden');
				openAssociatedModal.removeAttribute('hidden');
				openAssociatedModal.setAttribute('tabindex', "-1");
	
			})
		})
	
		const closeModal = (modalToClose) => {
			modalToClose.setAttribute('aria-hidden', 'true');
			modalToClose.setAttribute('hidden', '');
			modalToClose.removeAttribute('tabindex');
		}
	
		modalClose.forEach(item => {
			item.addEventListener('click', e => {
				stopDefaultAndPropagation(e);
				const closeModalId = item.getAttribute('data-modal-close');
				const closeAssociatedModal = document.getElementById(closeModalId);
				closeModal(closeAssociatedModal);
			})
		})
	
		// ----------------------------------------------------
		// Common modal functionality
		// ----------------------------------------------------
	
		modal.forEach(item => {
			// Click outside of the modal to close the modal
			const modalOpen = item.getAttribute('hidden');
			if(!modalOpen) {
				item.addEventListener('click', e => {
					if(!e.target.closest('.m_modal-dialog')) {
						closeModal(item);
					}
				})
			}	
		})
	
		window.addEventListener('keydown', e => {
			// Use the escape key to close the modal
			const currentlyOpenModal = document.querySelector('.m_modal:not([hidden])');
			if(e.keyCode === 27 && currentlyOpenModal) {
				closeModal(currentlyOpenModal);
			}
		});
	}
})()