;(function () {
	const toggleOpen = document.querySelectorAll('[data-toggle-trigger]');
	const toggleArray = Array.from(toggleOpen); // convert to array for older browsers
	if(toggleArray) {
		toggleArray.forEach(item => {
			item.addEventListener('click', e => {
				const toggleID = item.getAttribute('data-toggle-trigger');
				const associatedContent = document.querySelector(`[data-toggle-content=${toggleID}]`);
				const associatedTriggers = document.querySelectorAll(`[data-toggle-trigger=${toggleID}]`);
				if (associatedContent) {
					const contentDisplay = window.getComputedStyle(associatedContent)['display'];
	
					if (contentDisplay == 'none') {
						// There can be more than one trigger associated with a data-toggle-content container,
						// specifically in the case of the disclaimer content
						associatedTriggers.forEach(item => {
							item.classList.add('is-triggered');
						});
	
						associatedContent.removeAttribute('hidden');
					} else {
						associatedTriggers.forEach(item => {
							item.classList.remove('is-triggered');
						});
						associatedContent.setAttribute('hidden', '');
					}
				}
			})
		})
	}
})()