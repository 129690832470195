;(function () {
	// -------------------------------------------------------------
	// Pinned Container
	// -------------------------------------------------------------

	const pinnedContainer = document.querySelectorAll('.m_pin-on-scroll-wrapper');
	const isPinnedTop = 'is-pinned-top';
	const isPinnedBottom = 'is-pinned-bottom';

	const checkPin = e => {
		pinnedContainer.forEach(container => {
			const containerAt = window.scrollY;
			const containerHeight = container.offsetHeight;
			const pinnedItem = container.querySelector('.m_pin-on-scroll-item');
			const pinnedItemWidth = pinnedItem.offsetWidth;
			const pinnedItemHeight = pinnedItem.offsetHeight;
			const previousSibling = pinnedItem.previousElementSibling;
			let previousSiblingHeight = 0;

			if(previousSibling) {
				previousSiblingHeight = previousSibling.offsetHeight;
			}

			const resetStickyContainer = _ => {
				// Do no trigger pinned sidebar functionality before
				// the pinned container is higher on the page than the 
				// height of the sticky header.
				container.classList.remove(isPinnedBottom);
				container.classList.remove(isPinnedTop);
				pinnedItem.style.removeProperty('width');
				pinnedItem.style.removeProperty('top');
			}

			const pinToTopOfContainer = _ => {
				// Fix the sticky container to the top while the primary content is still in view.
				container.classList.add(isPinnedTop);
				container.classList.remove(isPinnedBottom);
				pinnedItem.style.top = `${global.getStickyHeaderHeight()}px`;
			}

			const pinToBottomOfContainer = _ => {
				// When scrolling past the primary content, position the sticky sidebar to the bottom of the container.
				container.classList.remove(isPinnedTop);
				container.classList.add(isPinnedBottom);
				pinnedItem.style.removeProperty('top');
			}

			if(global.screenSize() === 'desktop') {
				pinnedItem.style.width = `${pinnedItemWidth}px`;
				if(window.scrollY <= global.getStickyHeaderHeight() + previousSiblingHeight) {
					resetStickyContainer();
				} else if((window.scrollY > global.getStickyHeaderHeight() + previousSiblingHeight && window.scrollY  <  (containerHeight - pinnedItemHeight))) {
					pinToTopOfContainer();
					
				} else if((window.scrollY > global.getStickyHeaderHeight() && window.scrollY >= (containerHeight - pinnedItemHeight))) {
					pinToBottomOfContainer();
				}
			} else {
				resetStickyContainer();
			}
		});
	}

	if (pinnedContainer) {
		window.addEventListener('scroll', checkPin);
		// Recalculates on screen resizes.
		window.addEventListener('resize', checkPin);

		// In the case of back button functionality, if the page loads to a 
		// scrolled position, make sure the sidebar nav is positioned accordingly.
		window.addEventListener('load', checkPin);
	}
})()