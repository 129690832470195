;(function () {
	const lightboxImage = document.querySelectorAll('.m_lightbox-trigger-wrap');
	if(lightboxImage) {
		lightboxImage.forEach(item => {
			const image = item.querySelector('img');
			const src = image.getAttribute('src');
			const lightboxSrc = image.getAttribute('data-lightbox-src');
			const lightboxDescription = image.parentNode.nextElementSibling.innerHTML;
			let lightboxSrcUrl;
			
			lightboxSrc ? lightboxSrcUrl = lightboxSrc : lightboxSrcUrl = src;

			const createLightbox = e => {
				const lightboxTemplate = `
					<div class="m_lightbox-container">
						<div class="m_lightbox-image">
							<img src="${lightboxSrcUrl}" alt="">
						</div>
						<div class="m_lightbox-close">
							<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icons-close"></use></svg>
						</div>
					</div>
					<div class="m_lightbox-description">${lightboxDescription}</div>
				`
				const lightboxWrapper = document.createElement('div');
				lightboxWrapper.classList.add('m_lightbox-wrapper');
				global.body.prepend(lightboxWrapper);
				global.body.classList.add('fixed', 'w-full');
				lightboxWrapper.innerHTML = lightboxTemplate;
			}

			item.addEventListener('click', createLightbox);
		})
	}

	const removeLightbox = e => {
		if(e.target.closest('.m_lightbox-close')) {
			const parentWrapper = e.target.closest('.m_lightbox-wrapper');
			console.log(parentWrapper);
			parentWrapper.remove();
			global.body.classList.remove('fixed', 'w-full');
		}
	}
	
	global.body.addEventListener('click', removeLightbox);
	// For iOS
	global.body.addEventListener('touchstart', removeLightbox);

})()